var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    class: _vm.classes.concat(["icon--" + _vm.color]),
    style: {
      width: _vm.width ? _vm.width : null,
      minWidth: _vm.width ? _vm.width : null,
      height: _vm.height ? _vm.height : null,
      top: _vm.top ? _vm.top : null,
      fill: _vm.isNotStandardColor ? _vm.color : null,
    },
    domProps: {
      innerHTML: _vm._s(_vm.loading ? _vm.icons.singles.spinnerDrop : _vm.icon),
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }