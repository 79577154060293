<template>
  <span
    :class="[...classes, `icon--${color}`]"
    :style="{
      width: width ? width : null,
      minWidth: width ? width : null,
      height: height ? height : null,
      top: top ? top : null,
      fill: isNotStandardColor ? color : null,
    }"
    v-html="loading ? icons.singles.spinnerDrop : icon"
  ></span>
</template>

<script>
// Icons - Singles
import spinnerDrop from '@icons/interface/spinner-drop.svg';

export default {
  name: 'fs-button-icon',

  props: {
    icon: {},
    small: {
      type: Boolean,
      default: false,
    },
    width: String,
    height: String,
    // Colors
    // white
    color: String,
    top: String,

    loading: { type: Boolean, default: false },
    rightAligned: { type: Boolean, default: false },
  },

  data() {
    return {
      icons: {
        singles: {
          spinnerDrop,
        },
      },
    };
  },

  computed: {
    classes() {
      const classes = ['icon'];

      if (this.small) classes.push('icon--small');
      if (this.rightAligned) classes.push('icon--right-aligned');
      if (this.loading) classes.push('icon--spinning-svg');

      return classes;
    },
    isNotStandardColor() {
      return !(this.color === 'white' || this.color === 'dark-blue');
    },
  },
};
</script>

<style scoped lang="scss">
@use 'sass:math';

// ============================================================================
// ============================================================================
// Icon
.icon {
  width: 1.14em;
  min-width: 1.14em;
  height: 1.14em;
  min-height: 1.14em;
  margin-right: 0.57em;
  margin-left: -0.0714em;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  transition: fill 0.15s ease-in-out;

  &--small {
    ::v-deep svg {
      width: 1em;
      height: 1em;
    }
  }

  &--right-aligned {
    margin-right: -0.0714em;
    margin-left: 0.57em;
  }

  // Colors
  &--white {
    ::v-deep svg {
      fill: #fff;
    }
  }
  &--dark-blue {
    ::v-deep svg {
      fill: rgba(53, 73, 94, 1);
    }
  }

  &--spinning-svg {
    ::v-deep svg {
      animation: simpleSpinningAnimation;
      animation-duration: 0.45s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

@keyframes simpleSpinningAnimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
